@import 'colors';
@import 'breakpoints';

@mixin light() {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 300;
}

@mixin regular() {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 400;
}

@mixin medium() {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 500;
}

@mixin semibold() {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 600;
}

@mixin bold() {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 700;
}

@mixin script() {
  font-family: sheila, sans-serif;
  font-style: normal;
  font-weight: 700;
}

$letter-spacing: 1.5px;

h1 {
  @include medium();
  font-size: 2.25rem;

  @include md() {
    font-size: 4rem;
  }
}

h2 {
  @include semibold();
  font-size: 3rem;
}

h3 {
  @include semibold();
  font-size: 2rem;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
}

a {
  color: $primary;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:visited {
    color: $visited;
  }
}

.script {
  @include script();
}
