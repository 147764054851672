$primary: #161515;
$secondary: #a18d65;
$accent-1: #64272c;
$accent-2: #6d696b;
$neutral: #d3c29e;
$neutral-background: #ebe3d6;
$gray-background: #e2e1e1;
$visited: #642162;
$error: #b00020;
$success: #388E3C;
