h1 {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }
}

h2 {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
}

h3 {
  font-family: cormorant-garamond, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

a {
  color: #161515;
}
a:hover, a:focus {
  text-decoration: none;
}
a:visited {
  color: #642162;
}

.script {
  font-family: sheila, sans-serif;
  font-style: normal;
  font-weight: 700;
}