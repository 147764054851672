
@import '~@angular/material/theming';
@import 'colors';
@import 'typography';
@import 'breakpoints';

@include mat-core();

$the-look-theme: (
  primary: $primary,
  accent: $secondary,
  contrast: (
    primary: white,
    accent: $primary
  )
);

$the-look-photography-primary: mat-palette($the-look-theme, primary);
$the-look-photography-accent: mat-palette($the-look-theme, accent);
$the-look-photography-warn: mat-palette($mat-red);

$the-look-photography-theme: mat-light-theme((
  color: (
    primary: $the-look-photography-primary,
    accent: $the-look-photography-accent,
    warn: $the-look-photography-warn,
  )
));

@include angular-material-theme($the-look-photography-theme);

body {
  @include light();
  font-size: 21px;
  margin: 0;
}

main {
  height: calc(100vh - 21vh);
  min-height: 35rem;

  @include sm() {
    min-height: unset;
    height: calc(100vh - 18vh);
  }

  @include md() {
    height: calc(100vh - 14vh);
  }
}

html,
body {
  height: 100vh;
}

button {
  appearance: none;
  background-color: transparent;
  border: 1px solid currentColor;
  border-radius: 2px;
  color: #fff;
  letter-spacing: 0.1em;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px #fff;
    outline: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 80rem;
  padding: 1.5rem;
}
